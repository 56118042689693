import React from "react"
import styled from "styled-components"
import BaseImg from "./baseImg"

const Section = styled.div`
  display: flex;
  align-items: center;
  column-gap: 32px;

  @media screen and (max-width: 991px) {
    align-items: initial;
    flex-direction: column;
    column-gap: 0;
  }
`

const ImagesWrapper = styled.div`
  flex-basis: calc(50% - 16px);
  max-height: 1000px !important;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-height: 0;
  grid-row-gap: 40px;
  grid-column-gap: 30px;
  @media screen and (max-width: 991px) {
    max-height: initial;
  }

  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr 1fr;
  }
`

const SingleImageBox = styled.div`
  flex-basis: 30%;
  height: 52px;
  div {
    height: 100%;
  }
  img {
    margin-bottom: 0;
  }
`

const TextWrapper = styled.div`
  position: relative;
  flex-basis: calc(50% - 16px);
  @media screen and (max-width: 991px) {
    margin-bottom: 5rem;
  }
`

const BorderedBox = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 70%;
  height: calc(100% + 74px);
  border: 1px solid ${(props) => props.theme.secondary};
  border-left: none;
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 24px;
    border-left: 1px solid ${(props) => props.theme.secondary};
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
`

const Title = styled.h3`
  font-size: 40px;
`

const Description = styled.p`
  font-size: 16px;
`

const OurPartnersSection = ({ title, text, partnerImages }) => {
  return (
    <Section>
      <TextWrapper>
        <Title>{title}</Title>
        <Description>{text}</Description>
        <BorderedBox />
      </TextWrapper>
      <ImagesWrapper>
        {partnerImages.map((partnerImage) => (
          <SingleImageBox
            key={partnerImage.image.localFile.childImageSharp.fluid.base64}
          >
            <BaseImg localFile={partnerImage.image.localFile} />
          </SingleImageBox>
        ))}
      </ImagesWrapper>
    </Section>
  )
}

export default OurPartnersSection

import React from "react"
import styled from "styled-components"
import { BaseButton } from "./styledComponents"
import { MediumMobileFont } from "./typography"

const Header = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-family: "Hind";
`

const ServiceBox = styled.li`
  display: block;
  width: 100%;
  padding: 1.625rem;
  text-align: center;
  margin: 0 10px;
  @media (max-width: 1195px) {
    width: calc(50% - 2rem);
    margin: 1rem;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  .single-technology {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.75rem;
    margin: 10px 0;
    :last-of-type {
      margin-bottom: 1.5rem;
    }
    ::before {
      content: "+";
      margin: 2px 5px 0 0;
      font-size: 36px;
      color: ${(props) => props.theme.secondary};
    }
  }
`

const SingleService = ({ service }) => {
  const { title, text, technologies, image, url } = service
  return (
    <ServiceBox>
      <a
        href={url}
        style={{
          fontFamily: "Hind",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "170px",
              marginBottom: "1.625rem",
            }}
          >
            <img
              style={{ maxHeight: "170px", maxWidth: "170px" }}
              src={image.source_url}
              alt=""
            />
          </div>
          <Header>
            <MediumMobileFont
              desktopFontSize="1.5rem"
              desktopLineHeight="2.375rem"
              dangerouslySetInnerHTML={{ __html: title }}
            ></MediumMobileFont>
          </Header>
          <p dangerouslySetInnerHTML={{ __html: text }}></p>
          <div>
            {technologies
              ? technologies.map((technology) => (
                  <p key={technology} className="single-technology">
                    {technology}
                  </p>
                ))
              : null}
          </div>
        </div>
      </a>
    </ServiceBox>
  )
}

export default SingleService

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { useKeenSlider } from "keen-slider/react"
import BaseImg from "../baseImg"
import "keen-slider/keen-slider.min.css"

const Slide = styled.a`
  display: flex;
`

const SlideInner = styled.div`
  transform: ${(props) => (props.scale === "1" ? "scale(0.7)" : "")};
  transition: all 0.3s ease;
`

const Title = styled.h3`
  text-align: center;

  > span {
    display: block;
    font-weight: 400;
    font-size: 24px;
  }

  > span:first-child {
    font-size: 18px;
    font-weight: 700;
  }
`

const Dots = styled.div`
  display: flex;
  padding: 10px 0;
  justify-content: center;

  .dot {
    border: none;
    width: 10px;
    height: 10px;
    background: #c5c5c5;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
  }

  .dot:focus {
    outline: none;
  }

  .dot.active {
    background: #000;
  }
`

const PortfolioCarousel = () => {
  const { allWordpressWpProject } = useStaticQuery(graphql`
    query {
      allWordpressWpProject {
        nodes {
          cmb2 {
            metabox_post_project {
              title
              subtitle
              services
              buttonUrl
              carouselImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          project_taxonomy
        }
      }
    }
  `)

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [projects, setProjects] = useState(allWordpressWpProject.nodes)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setProjects(
      allWordpressWpProject.nodes.filter(
        (project) => project.cmb2.metabox_post_project.carouselImage
      )
    )
  }, [allWordpressWpProject.nodes])

  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      origin: "center",
      perView: 3,
      spacing: 25,
    },
    loop: true,
    breakpoints: {
      "(max-width: 1920px)": {
        slides: {
          origin: "center",
          perView: 2.6,
          spacing: 15,
        },
      },
      "(max-width: 1366px)": {
        slides: { perView: 2, spacing: 5, origin: "center" },
      },
      "(max-width: 565px)": {
        slides: { perView: 1, origin: "center" },
      },
    },

    slideChanged(s) {
      setCurrentSlideIndex(s.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })

  return (
    <>
      <div ref={sliderRef} className="keen-slider">
        {projects.map((project, index) => {
          const {
            carouselImage,
            title,
            buttonUrl,
            subtitle,
          } = project.cmb2.metabox_post_project

          return (
            <div className="keen-slider__slide">
              <a href={buttonUrl} key={title + buttonUrl}>
                <SlideInner scale={index !== currentSlideIndex ? "1" : "0"}>
                  <BaseImg
                    alt=""
                    localFile={carouselImage ? carouselImage.localFile : ""}
                  />
                  <Title>
                    <span>{title}</span>
                    {subtitle ? <span>{subtitle}</span> : null}
                  </Title>
                </SlideInner>
              </a>
            </div>
          )
        })}
      </div>
      {loaded && instanceRef.current && (
        <Dots className="dots">
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => instanceRef.current?.moveToIdx(idx)}
                className={"dot" + (currentSlideIndex === idx ? " active" : "")}
              ></button>
            )
          })}
        </Dots>
      )}
    </>
  )
}

export default PortfolioCarousel

import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { BaseButton } from "./styledComponents"
import { HugeMobileFont, BigMobileFont } from "./typography"
import { joinMenuToLocation } from "../helpers"
import Shopify from "../images/svgs/shopify-partner.svg"
import ShopifyPlus from "../images/svgs/shopify-plus.svg"

const Menu = styled.ul`
  margin-left: 0;
  padding-left: 2rem;
  border-left: 1px ${(props) => props.theme.secondary} solid;
`

const MenuItem = styled.li`
  font-weight: bold;
  display: block;
  white-space: nowrap;
  margin-bottom: 1.5rem;
  @media screen and (max-width: 768px) {
    white-space: normal;
  }
`
const MenuText = styled.div`
  font-size: 24px;
  line-height: 38px;
  font-weight: normal;
`

const MenuTitle = styled.p`
  display: inline-block;
  font-weight: 700;
  font-family: "Montserrat";
  padding: 0 0 1rem 0;
  color: ${(props) => props.theme.secondary};
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`
const MenuBottomTitle = styled.div`
  display: inline-block;
  font-weight: 700;
  font-family: "Montserrat";
  padding: 1.5rem 0;
  color: ${(props) => props.theme.secondary};
  @media screen and (max-width: 600px) {
    padding: 50px 0 0;
    display: flex;
    justify-content: center;
  }
`

const HomapageMenuWrapper = styled.div`
  margin-bottom: 5%;
  flex: 40rem 1 0;
  margin-right: -10rem;
  @media screen and (max-width: 900px) {
    flex: auto 1 1;
    margin-right: 0;
  }
`

const ButtonsWrapper = styled.div`
  margin-top: 40px;
  a {
    :nth-last-child(2) {
      @media screen and (max-width: 551px) {
        margin-bottom: 24px;
      }
      @media screen and (min-width: 520px) {
        margin-right: 24px;
      }
    }
  }
`

const LogoWrapper = styled.div`
  max-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 600px) {
    max-height: 180px;
    flex-direction: column;
    height: auto;
  }

  svg {
    width: 220px;
  }

  #layer_plus {
    width: 160px;
  }
`

const HomepageMenu = ({ menuTitle, bottomTitle }) => {
  const {
    allWordpressWpApiMenusMenusItems,
    allWordpressWpApiMenusMenuLocations,
  } = useStaticQuery(
    graphql`
      query {
        allWordpressWpApiMenusMenuLocations(
          filter: { label: { eq: "Services" } }
        ) {
          nodes {
            label
            wordpress_id
          }
        }
        allWordpressWpApiMenusMenusItems {
          nodes {
            wordpress_id
            items {
              order
              title
              url
            }
          }
        }
      }
    `
  )
  const servicesMenu = joinMenuToLocation(
    allWordpressWpApiMenusMenuLocations.nodes[0],
    allWordpressWpApiMenusMenusItems.nodes
  )

  return (
    <HomapageMenuWrapper>
      <MenuTitle>
        <HugeMobileFont>{menuTitle}</HugeMobileFont>
      </MenuTitle>
      <MenuText>{bottomTitle}</MenuText>
      <ButtonsWrapper>
        <BaseButton to="/free-project-estimation" size="small">
          get in touch
        </BaseButton>
      </ButtonsWrapper>
      <MenuBottomTitle>
        <LogoWrapper>
          <Shopify />
          <ShopifyPlus />
        </LogoWrapper>
      </MenuBottomTitle>
    </HomapageMenuWrapper>
  )
}

export default HomepageMenu

import React from "react"
import { Container, BaseButton } from "./styledComponents"
import CenterBorderedHeader from "./centerBorderedHeader"
import SingleService from "./singleService"
import styled from "styled-components"
import { BigMobileFont } from "./typography"

const ServiceList = styled.ul`
  display: flex;
  margin-top: 6.3rem;
  margin-left: 0;
  @media (max-width: 1195px) {
    flex-wrap: wrap;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ServicesSection = ({ title, buttonText, buttonUrl, services }) => {
  return (
    <div style={{ margin: "80px 0 0 0" }}>
      <Container>
        <CenterBorderedHeader>
          <BigMobileFont desktopFontSize="2.5rem">{title}</BigMobileFont>
        </CenterBorderedHeader>
        <ServiceList>
          {services.map((service, index) => {
            return (
              <SingleService key={service.title + index} service={service} />
            )
          })}
        </ServiceList>
        {buttonText && buttonUrl ? (
          <ButtonWrapper>
            <BaseButton to={buttonUrl}>{buttonText}</BaseButton>
          </ButtonWrapper>
        ) : null}
      </Container>
    </div>
  )
}

export default ServicesSection
